import axios from '@axios'
import axiosRu from '@axios-ru'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchArtists(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/artist', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchArtists(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/artist/search', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchArtistDetails(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/artist/${id}`, {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createArtist(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/artist`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSimilarArtists(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/artist/${id}/similar`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    ignoreTracks(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/artist/${id}/ignore-missing`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTrack(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/track/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    additionalTracks(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/artist/${id}/additional-tracks`, {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    loadLastfmInfo(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/artist/${id}/load-lastfm-info`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTrack(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/artist/${id}/add-track`, queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTrack(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/track/${id}`, queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchTrack(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axiosRu
          .get(`/track/${id}/search`, {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadTrack(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axiosRu
          .get(`/track/${id}/download`, {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopics(ctx, {queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/autotopic', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAutotopic(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/autotopic/${id}`, queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createAutotopic(ctx, {queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/autotopic`, queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    yandexPlaylistSearch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosRu
            .get('/yandex/playlist-search', {params: queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    yandexPlaylistSave(ctx, {id, owner_id, queryParams}) {
      return new Promise((resolve, reject) => {
        axiosRu
            .post(`/yandex/playlist/save/${owner_id}/${id}`, {params: queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    yandexPlaylistView(ctx, {id, owner_id, queryParams}) {
      return new Promise((resolve, reject) => {
        axiosRu
            .get(`/yandex/playlist/${owner_id}/${id}`, {params: queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    spotifyPlaylistSearch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            .get('/spotify/playlist-search', {params: queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    spotifyPlaylistView(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/spotify/playlist/${id}`, {params: queryParams})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
